export var organizers = [
	{
		name: "Prof. Dr. Gerhard P. Fettweis",
		affiliation: "TU&nbsp;Dresden /<br>Barkhausen Institut",
		portrait: "GerhardFettweis.jpg",
		role: "General<br>Co-Chair",
	},
	{
		name: "Prof. Dr. Markku Juntti",
		affiliation: "University of Oulu",
		portrait: "MarkkuJuntti.jpg",
		role: "General<br>Co-Chair",
	},
	{
		name: "André Bourdoux",
		affiliation: "imec",
		portrait: "AndreBourdoux.jpg",
		role: "TPC<br>Co-Chair",
	},
	{
		name: "Prof. Maria Sabrina Greco",
		affiliation: "University of Pisa",
		portrait: "Sabrina_Greco.JPG",
		role: "TPC<br>Co-Chair",
	},
	{
		name: "Dr. Padmanava Sen",
		affiliation: "Barkhausen Institut",
		portrait: "sen.jpg",
		role: "TPC<br>Co-Chair",
   },
	//{
	//	name: "Dr. Fan Liu",
	//	affiliation: "SUSTech",
	//	portrait: "Fan_Liu.jpg",
	//	role: "TPC<br>Co-Chair",
	//},
	{
		name: "Prof. Dr. Sofie Pollin",
		affiliation: "KU Leuven",
		portrait: "SofiePollin.jpg",
		role: "Senior Program<br>Co-Chair",
	},
	{
		name: "Prof. Dr. Husheng Li",
		affiliation: "Purdue University",
		portrait: "Husheng Li.jpeg",
		role: "Program<br>Co-Chair",
	},
	{
		name: "Vlad Andrei",
		affiliation: "TU Munich",
		portrait: "VladAndrei.jpg",
		role: "Junior Program<br>Co-Chair",
	},
	{
		name: "Dr. Amr Haj-Omar",
		affiliation: "NI",
		portrait: "Amr-Haj-Omar.jpg",
		role: "Exhibition and Demo<br>Chair",
	},
	// {
	// 	name: "Prof. Dr. Thomas Ußmüller",
	// 	affiliation: "Universität Innsbruck",
	// 	portrait: "ussmueller.jpg",
	// 	role: "General<br>Co-Chair",
	// },	
	//{
	//	name: "Björn Debaillie",
	//	affiliation: "imec",
	//	portrait: "BjoernDebaillie.jpg",
	//	role: "Organizing<br>Chair",
	//},
	{
		name: "Dr. Ahmad Nimr",
		affiliation: "TU Dresden",
		portrait: "AhmadNimr.jpg",
		role: "Publication<br>Chair",
   },
	{
		name: "Dr. Rico Radeke",
		affiliation: "5G Lab",
		portrait: "RicoRadeke.jpg",
		role: "Finance<br>Chair",
	},
	//{
	//	name: "Kati Kantonen",
	//	affiliation: "University of Oulu",
	//	portrait: "katikantonen.JPG",
	//	role: "Local Arrangements<br>Co-Chair",
	//},
	{
		name: "Harri Saarnisaari",
		affiliation: "University of Oulu",
		portrait: "Harri_Saarnisaari01.jpg",
		role: "Local Arrangements<br>Co-Chair",
	},
	{
		name: "Nicole Flechs",
		affiliation: "TU&nbsp;Dresden",
		portrait: "nicole_flechs.jpg",
		role: "Industry<br>Relations&nbsp;Chair",
	},
	{
	 	name: "Amelie Hagelauer",
	 	affiliation: "Fraunhofer EMFT & TU München",
	 	portrait: "2207_Hagelauer.png",
	 	role: "IEEE MTT-S <br>Liaison",
	},
	// {
	// 	name: "Markus Gardill",
	// 	affiliation: "BTU Cottbus",
	// 	portrait: "francisco_arroyo.jpg",

	// },

	//{
	//	name: "Fabian Diehm",
	//	affiliation: "Barkhausen&nbsp;Institut",
	//	portrait: "fabian_diehm.jpg",
	//	role: "Web<br>Chair",
	//},

];
