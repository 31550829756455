
// Data Structure:

// {
// 		title: "Opening",
// 		start: "",
// 		end: "",
// 		break: true, 		// <- true | false
// 		vimeo: "",  // <- "vimeoID"
// 		youtube: "", // <- "youtubeID"
// 		slides: "filename.pdf", // <- "Filename Slides"
// 		slides: [ // <- Multiple slides
// 			{
// 				name: "Title",
// 				file: "Filename.pdf"
// 			},
// 			...
//      ],
// 		chair: {
// 			name: "Andre Bourdoux",
// 			affiliation: "IMEC, Belgium"
// 		},
// 		papers: [
// 			{
// 				"title": "The Paper Title",
// 				"authors": "A. Bourdoux, T. Ußmüller",
// 				"ptype": "virtual"
// 			},
// 			{
// 				"title": "Yet Another Paper Title",
// 				"authors": "J. Doe, J. Smith",
// 				"ptype": "physical"
// 			},
// 		],
// 		speaker: "Fabian Diehm",
// 		affiliation: "TU Dresden, Dresden, Germany",
// 		ptype: "virtual", 	// <- "physical" | "virtual"
// 		misc: "", 			// <- any html possible
// },


export const programdata = [
	{
		title: "Day 1",
		date: "2025-01-28",
		//slidefolder: "/slides/2023/AE091EGS",
		items: [
			{
				title: "Registration and welcome coffee",
				start: "8:30",
				end: "9:00",
				break: true,
			},
			{
				title: "Tutorial 1: Integrated Sensing and Imaging: Fundamentals and Methods",
				start: "9:00",
				end: "10:15",
				speaker: "Dario Tagliaferri (Politecnico di Milano, Italy), Marouan Mizmizi (Politecnico di Milano, Italy)",
			//	chair: {
			//		name: "... ",
			//		affiliation: "IMEC, Belgium"
			//	},
			//	slides: "JC&S_Tutorial 1.pdf",
			//	vimeo: "928690369",
				misc: `
					<div style="margin-top: 0.5em; font-size: 0.9em; font-style: italic;">
						Abstract: Integrated Sensing and Communication (ISAC) systems are recognized as one of the pillars of the future generation of wireless systems, representing a paradigm shift that entails two distinct functionalities into a single device, with fully shared hardware and radio resources. Within the ISAC community, “sensing” is often used as a synonym for “localization”, whereby the goal is to infer meaningful parameters characterizing the state of a set of targets of interest, e.g., position, velocity and possibly orientation. Recently, efforts on ISAC research started focusing on radio imaging, namely the generation of 2D or 3D maps of the reflectivity of the environment at the frequency of operation, from which to infer the number and the shape of targets through detection and image processing/interpretation. Conceptually, radio imaging is the main pre-requisite for localization, that follows detection on the generated image. This tutorial aims at covering the main aspects of Integrated Communication and Imaging (ICAI) systems. We commence by developing the fundamentals, namely outlining and discussing the diffraction tomography theory and the related principles, that rule the resolution of any radio imaging system. Then, we discuss the difference between ISAC and ICAI systems, in terms of both the communication-sensing trade-off and the analogies between diffraction tomography theory and estimation theory, attempting to bridge the gap with the existing ISAC literature. Lastly, we delve into ICAI methods, outlining practical synchronization techniques to enable networked ICAI systems over wireless networks.
					</div>`
			},
			{
				title: "30min Coffee Break",
				start: "10:15",
				end: "10:45",
				break: true,
			},
			{
				title: "Workshop 1: Workshop on Multi-Disciplinary Approaches to Joint Communication and Sensing in 6G",
				start: "10:45",
				end: "13:00",
				speaker: "Marja Matinmikko-Blue (University of Oulu), Gabor Fodor (University of Oulu), Christos Masouros (University of Oulu), Matti Latva-aho (University of Oulu), Arthur S. de Sena (University of Oulu), Marcos Katz (University of Oulu), Miguel Bordallo-Lopez (University of Oulu)",
			//	chair: {
			//		name: "Prof. Marco Di Renzo",
			//		affiliation: "Paris-Saclay University, France"
			//	},
			//	slides: "Tutorial 2.pdf",
			//	vimeo: "928690610",
				misc: `<div style="margin-top: 0.5em; font-size: 0.9em; font-style: italic;">
					Abstract: follows.
				</div>`
			},
			{
				title: "Lunch Break",
				start: "30:00",
				end: "14:00",
				break: true,
			},
			{
				title: "Workshop 2: Joint Communication and Sensing (JC&S) for Future Interactive, Immersive, and Intelligent (I3) Connectivity Beyond Communications",
				start: "14:00",
				end: "16:15",
				speaker: "Padmanava Sen (Barkhausen Institut), Angeliki Alexiou (University of Piraeus), Nhan Ngyuen (University of Oulu), Simon Schütze (Fraunhofer Heinrich Hertz Institute (HHI)",
			//	chair: {
			//		name: "Prof. Visa Koivunen",
			//		affiliation: "Aalto University, Finland"
			//	},
			//	slides: "Tutorial 3.pdf",
			//	vimeo: "928690673",
				misc: `<div style="margin-top: 0.5em; font-size: 0.9em; font-style: italic;">
					Abstract: This workshop will focus on the different building blocks towards the I3 connectivity beyond communications. The talks will be aligned with the goals of the EU project INSTINCT (http://instinct-6g.eu/) but will not be restricted to the project. There are four sessions with the first presentation (session 1) highlighting the overarching goals of incorporating JC&S in future wireless networks that are envisioned to incorporate several functionalities, enabling previously unattainable data rates, tunability and perceptive intelligence. That will be followed by three topical deep dives (sessions 2 - 4) on near-field wavefront engineering, massive multiple-input multiple-output (MIMO) and wideband hardware/software enablement respectively for JC&S, summarized below. 
				</div>`
			},
			{
				title: "30min Coffee Break",
				start: "16:15",
				end: "16:45",
				break: true,
			},
			{
				title: "Tutorial 2: Security and Privacy Aspects of Joint Communication & Sensing (JC&S)",
				start: "16:45",
				end: "18:00",
				speaker: "Stefan Köpsell (Barkhausen Institut), Prajnamaya Daas (Barkhausen Institut)",
			//	chair: {
			//		name: "Prof. Mikko Valkama",
			//		affiliation: "Tampere University, Finland"
			//	},
			//	slides: "Tutorial 4.pdf",
			//	vimeo: "928690795",
				misc: `<div style="margin-top: 0.5em; font-size: 0.9em; font-style: italic;">
					Abstract: Joint Communication and Sensing (JC&S) is a key technology envisioned to play a major role in the  future of 6G networks, where communication and environmental sensing are integrated into a unified framework. While JC&S offers numerous exciting applications across fields like industrial sectors, smart cities, and healthcare applications, it also introduces significant security and privacy challenges. Sensing systems often collect sensitive information, such as personally identifiable information (PII) and confidential data from both human and non-human targets, which can be vulnerable to attacks or misuse. This course aims to provide a foundational understanding of these security and privacy risks in JC&S, discuss the technical, organisational, legal and regulatory requirements for addressing them, and explore relevant policies and guidelines necessary to ensure the safe and secure deployment of this transformative technology.
				</div>`
			},
			{
				title: "Warm Drink in front of Hotel Lasaretti",
				start: "18:15",
				break: true,
			},
			//{
			//	title: "Tutorial 5: Joint Communication and Sensing for Space: Understanding the Convergence of Space Functions through employing repurposable payloads",
			//	start: "16:30",
			//	end: "18:00",
			//	speaker: "Adam Kapovits (Eurescom), Helmut Zaglauer (Airbus), Jung‐Hyo Kim (Airbus), Marius Corici (FhG‐fokus), Cristian Grozea (FhG‐fokus)",
			//	chair: {
			//		name: "Prof. Markku Juntti",
			//		affiliation: "University of Oulu, Finland"
			//	},
			//	slides: "Tutorial 5.pdf",
			//	vimeo: "928690868",
			//	misc: `<div style="margin-top: 0.5em; font-size: 0.9em; font-style: italic;">
			//		Abstract:  Joint (or Integrated) Communication and Sensing (JC&S) has become a very hot  topic gaining plenty of attention in recent years in particular in the context of the evolution of 5G mobile telecommunications networks towards Beyond 5G (B5G) and 6G. Currently the JC&S applications that are being discussed are almost entirely in the terrestrial domain. However, there are already some significant efforts to address Joint Communication and Sensing using space-based assets or infrastructure as both sensing and communication are part of what is already offered by space-borne infrastructures. Recently for instance the use of MIMO LEO satellite systems with a beam squint-aware precoding scheme to operate communications and target sensing applications simultaneously has been proposed. But there are also many other potential applications where RF sensing from space and 5G Non-terrestrial network (NTN) communications can align to provide new capabilities and services. The emergence of 5G NTN in 3GPP standardization and the expectation that it will be an integral part of 6G necessitates a more thorough investigation of potential commonalities and synergies between JC&S and NTN. The convergence of space functions promises a much more efficient use of space assets with increasing business viability. The tutorial will provide the basic elements to understand the requirements, system concepts and potential benefits of Joint Communication and Sensing from Space.
			//	</div>`
			//},
			{
				title: "Walk to City Hall Oulu",
				start: "18:30",
				break: true,
			},
			{
				title: "Welcome Reception",
				start: "19:00",
				end: "20:30",
				break: true,
			}
		]
	},
	{
		title: "Day 2",
		date: "2025-01-29",
		slidefolder: "/slides/2023/AE091EGS",
		items: [
			{
				title: "Registration and Welcome Coffee",
				start: "8:30",
				end: "8:45",
				break: true,
			},
			{
				title: "Conference Opening - Welcome Address",
				speaker: "Markku Juntti (University of Oulu), Prof. Gerhard Fettweis (TU Dresden)",
				start: "8:45",
				end: "9:00",
			//	slides: "Opening-slides.pdf",
			//	vimeo: "928690975",
			},
			{
				title: "Keynote: Network as a Sensor: Evolution Toward 6G and Beyond",
				speaker: "Slawomir Stanczak",
				affiliation: "Heinrich Hertz Institute/TU Berlin",
				start: "9:00",
				end: "9:45",
			//	slides: "Petropulu-slides.pdf",
			//	vimeo: "928690995",
			},
			{
				title: "Keynote: Information theoretic Foundation of ISAC",
				speaker: "Holger Boche",
				affiliation: "TU Munich",
				start: "9:45",
				end: "10:30",
			//	slides: "famaey-slides.pdf",
			//	vimeo: "928691044",
			},
			{
				title: "30min Coffee Break (incl. Demos)",
				start: "10:30",
				end: "11:00",
				break: true,
			},
			{
				title: "Topical paper session 1 (5 papers)",
			//	speaker: "Sofie Pollin",
			//	affiliation: "KUL",
				start: "11:00",
				end: "12:30",
			//	papers: [
			//		{
			//			"title": "D‐Band Channel Modelling by 3D Ray Tracing for Joint Communications and Sensing",
			//			"authors": "Yunqi Feng (Ghent University & IMEC, Belgium); Wout Joseph (Ghent University/IMEC, Belgium); Emmeric Tanghe (Ghent University, Belgium); Andre Bourdoux (IMEC, Belgium); Mamoun Guenach (Imec, Leuven, Belgium)",
			//		},
			//		{
			//			"title": "Sounding‐Based Evaluation of Multi‐Sensor ISAC Networks for Drone Applications: Measurement and Simulation Perspectives",
			//			"authors": "Julia Beuster (Technische Universität Ilmenau & Fraunhofer Institute for Integrated Circuits IIS, Germany); Carsten Jan Smeenk (Fraunhofer Institute for Integrated Circuits & TU Ilmenau, Germany); Saw James Myint, Reza Faramarzahanagri, Carsten Andrich, Sebastian Giehl and Christian Schneider (Technische Universität Ilmenau, Germany); Reiner S. Thomä (Ilmenau University of Technology, Germany)",
			//		},
			//		{
			//			"title": "Fusion of Active and Passive Measurements for Robust and Scalable Positioning",
			//			"authors": "Hong Zhu, Alexander Venus and Erik Leitinger (Graz University of Technology, Austria); Stefan Tertinek (NXP Semiconductors, Austria); Klaus Witrisal (Graz University of Technology, Austria)"
			//		},
			//		{
			//			"title": "Static Human Position Classification from Indoor mmWave Radio RSSI Measurements",
			//			"authors": "Silvia Zampato (University of Padova, Italy); Praneeth Susarla (University of Oulu, Finland); Markku Jokinen (University of Oulu & Centre for Wireless Communications, Finland); Nuutti Tervo and Marko E Leinonen (University of Oulu, Finland); Miguel Bordallo Lopez (University of Oulu, Finland & VTT Technical Research Centre of Finland Ltd., Finland); Markku Juntti (University of Oulu, Finland); Michele Rossi (University of Padova, Italy); Olli Silvén (University of Oulu, Finland)",
			//		},
			//		{
			//			"title": "Multistatic Sensing Performance Maps for Evaluating Integrated Sensing and Communication Deployments",
			//			"authors": "Christopher Mollén (Ericsson AB, Sweden); Jörg Huschke (Ericsson, Germany); Robert Baldemair (Ericsson AB & Ericsson Research, Sweden); Gabor Fodor (Ericsson Research & Royal Institute of Technology (KTH), Sweden)",
			//		},
			//	],
			//	vimeo: "928691082",
			},
			{
				title: "Business Lunch",
				start: "12:30",
				end: "13:30",
				break: true,
			},
			{
				title: "Topical paper session 2 (4 papers)",
				start: "13:30",
				end: "14:45",
			//	chair: {
 			//	name: "Fan Liu",
 			//	affiliation: "SUSTech"
			//	},
			//	papers: [
			//		{
			//			"title": "Circularly Polarized Dual Antenna with Crescent Axed Sporadic Meander Traces Isolated by Shorting Pins and Defected Ground for Wi‐Fi Sensing",
			//			"authors": "Shahanawaz Kamal and Jan Adler (Barkhausen Institut, Germany); Maximilian Matthe (Barkhausen Institute, Germany); Padmanava Sen (Research Group Leader, Barkhausen Institut gGmbH, Germany)",
			//		},
			//		{
			//			"title": "People Flow Estimation with a Wi‐Fi‐Based Passive Radar",
			//			"authors": "Laurent Storrer (Univesité Libre de Bruxelles (ULB), Belgium); Dejvi Cakoni (Université Libre de Bruxelles, Belgium); Hasan Can Yildirim (Université Libre de Bruxelles (ULB), Belgium); Martin Willame (Université Catholique de Louvain (UCLouvain) & Université Libre de Bruxelles (ULB), Belgium); Jerome Louveaux (Université catholique de Louvain, Belgium); Philippe De Doncker (ULB, Belgium); Sofie Pollin (KU Leuven, Belgium); François Horlin (Université Libre de Bruxelles, Belgium))",
			//		},
			//		{
			//			"title": "A Digital Twinning Platform for Integrated Sensing, Communications and Robotics",
			//			"authors": "Vlad Costin Andrei (Technische Unversität München, Germany); Xinyang Li and Maresa Fees (Technical University of Munich, Germany); Andreas Feik (ETH Zurich, Switzerland); Ullrich J Mönich (Technische Universität München, Germany); Holger Boche (Technical University Munich, Germany)"
			//		},
			//		{
			//			"title": "Gesture Detection Using IEEE 802.15.4z Compliant Impulse Radio UWB Radar System",
			//			"authors": "Amirashkan Farsaei and Bernard Meyer (IMEC, The Netherlands); Liwei Zhou (Eindhoven University of Technology, The Netherlands); Alireza Sheikh (IMEC Nederland, The Netherlands); Gururaja Kasanadi Ramachandr and Utku Kumbul (IMEC, The Netherlands); Mohieddine El Soussi (Imec, The Netherlands); Peng Zhang (IMEC the Netherlands, The Netherlands)",
			//		},
			//		{
			//			"title": "Sensing in the Third Dimension: UWB‐Based Sensing in Urban Canyons",
			//			"authors": "Fabian de Ponte Müller, Martin Schmidhammer, Christian Gentner, Ibrahim Rashdan and Stephan Sand (German Aerospace Center (DLR), Germany)",
			//		},
			//	],
			//	vimeo: "928691155",
			},
			{
				title: "Demo teasers",
				start: "14:45",
				end: "15:00",
			//	vimeo: "928691217"
			},
			{
				title: "Demo Show + Coffee Break",
				start: "15:00",
				end: "15:30",
				break: true,
			},

			{
				title: "Topical paper session 3 (4 papers)",
				start: "15:30",
				end: "16:45",
			//	chair: {
 			//	name: "Markku Juntti",
 			//	affiliation: "UOULU"
			//	},
			//	papers: [
			//		{
			//			"title": "Joint Communication and State Sensing Under Logarithmic Loss",
			//			"authors": "Hamdi Joudeh (Eindhoven University of Technology, The Netherlands); Giuseppe Caire (Technische Universität Berlin, Germany)",
			//		},
			//		{
			//			"title": "Trellis Shaping for Joint Communications and Sensing: A Duality to PAPR Mitigation",
			//			"authors": "Husheng Li (Purdue University, USA); Zhu Han (University of Houston, USA); H. Vincent Poor (Princeton University, USA)",
			//		},
			//		{
			//			"title": "Enhancing Frequency Hopping Security in ISAC Systems: A Physical Layer Security Approach",
			//			"authors": "Ali Khandan Boroujeni (Barkhausen Institute GmbH Dresden, Germany); Ghazal Bagheri (Dresden, University of Technology, Germany); Stefan Köpsell (TU Dresden, Germany)"
			//		},
			//		{
			//			"title": "Robustness of ISAC Waveforms to Power Amplifier Distortion",
			//			"authors": "Abdur Rahman Mohamed Ismail (University of Ghent & IMEC, Belgium); Mamoun Guenach (Imec, Leuven, Belgium); Adham Sakhnini (Imec, Belgium); Andre Bourdoux (IMEC, Belgium); Heidi Steendam (Ghent University, Belgium)",
			//		},
			//	],
			//	vimeo: "928691230",
			},
			{
				title: "Panel Discussion",
				chair: {
					name: "Prof. Gerhard Fettweis and Markku Juntti",
					affiliation: "TU Dresden and University of Oulu"
							},
				speaker: "Gonzalo Seco Granados (Universitat Autonoma of Barcelona), Marja Matinmikko‐Blue (University of Oulu), Petteri Alinikula (Saab), Miguel Bordallo-Lopez (University of Oulu)",
				start: "16:45",
				end: "18:00",
		//slides: [ 
		//{
		//		name: "Intro Richard Stirling-Gallacher", 
		//		file: "panel_stirling-gallacher.pdf"
		//	}, 
		//	{
		//		name: "Intro Marja Matinmikko-Blue", 
		//		file: "panel_matinmikko-blue.pdf"
		//	},
		//	{
		//		name: "Intro Sofie Pollin", 
		//		file: "panel_pollin.pdf"
		//	},
		//	{
		//		name: "Intro Pandharipande", 
		//		file: "panel_pandharipande.pdf"
		//	}
		//],
		//vimeo: "928691280"
			},
			{
				title: "Transfer to Social Event",
				start: "18:15",
				break: true,
			},
			{
				title: "Social Dinner",
				misc: `
					<div style="margin-top: 0.5em; font-size: 0.9em; font-style: italic;">
					</div>`,
				start: "19:00",
				break: true,
			},
			
		]
	},
	{
		title: "Day 3",
		date: "2025-01-30",
		slidefolder: "/slides/2023/AE091EGS",
		items: [
			{
				title: "Morning Coffee",
				start: "8:30",
				end: "9:00",
				break: true,
			},
			{
				title: "Keynote",
				speaker: "Zhu Han",
				affiliation: "University of Houston",
				start: "09:00",
				end: "09:45",
		//slides: "lops-slides.pdf",
		//vimeo: "928691393",
			},
			{
				title: "Keynote",
				speaker: "to be announced",
		//affiliation: "NXP",
				start: "09:45",
				end: "10:30",
		//slides: "leong-slides.pdf",
		//vimeo: "928691432",
			},
			{
				title: "30min Break (incl. Demos)",
				start: "10:30",
				end: "11:00",
				break: true,
			},
			{
				title: "Topical paper session 4 (5 papers)",
				start: "11:00",
				end: "12:25",
		//chair: {
 		//	name: "Ahmad Nimr",
 		//	affiliation: "TU Dresden, Germany"
		//},
		//papers: [
		//	{
		//		"title": "Successive Threshold‐Based Multipath Mitigation Aided by Neural Network for UWB Ranging",
		//		"authors": "Alireza Sheikh (IMEC Nederland, The Netherlands); Bo Yang (Eindhoven University of Technology, The Netherlands); Mohieddine El Soussi (Imec, The Netherlands); Amirashkan Farsaei (IMEC, The Netherlands); Peng Zhang (IMEC the Netherlands, The Netherlands)",
		//	},
		//	{
		//		"title": "A Diagonal Algorithm with Low Complexity for Joint Range, Velocity, and Angle Estimation",
		//		"authors": "Yi Geng (Cict Mobile, China)",
		//	},
		//	{
		//		"title": "Angle‐Of‐Departure Estimation Algorithms for True‐Time‐Delay Antenna Arrays with Software‐Defined Radio",
		//		"authors": "Carl R. Collmann (TU Dresden, Germany); Ana Belen Martinez, Ahmad Nimr and Gerhard P. Fettweis (Technische Universität Dresden, Germany)",
		//	},
		//	{
		//		"title": "Bi‐Static Sensing with 5G NR Physical Uplink Shared Channel Transmission",
		//		"authors": "Visa Tapio, Nuutti Tervo, Marko E Leinonen and Aarno Pärssinen (University of Oulu, Finland)",
		//	},
		//	{
		//		"title": "CSI4Free: GAN‐Augmented mmWave CSI for Improved Pose Classification",
		//		"authors": "Nabeel Nisar Bhat (University of Antwerp - Imec, Belgium & Imec, Belgium); Rafael Berkvens (University of Antwerp - imec, Belgium); Jeroen Famaey (University of Antwerp & Imec, Belgium)",
		//	},
		//	{
		//		"title": "On Filtering and Complexity Reduction for Subspace‐Based Superresolution Path Delay Estimation Algorithms",
		//		"authors": "Zhongju Li, Ahmad Nimr, Philipp Schulz and Gerhard P. Fettweis (Technische Universität Dresden, Germany)",
		//	},
		//],
		//vimeo: "928691470",
			},
			{
				title: "Business Lunch",
				start: "12:30",
				end: "13:30",
				break: true,
			},
		
			{
				title: "Topical paper session 5 (5 papers)",
				start: "13:30",
				end: "15:00",
		//chair: {
 		//name: "Utku Kumbul",
 		//affiliation: "imec-nl"
		//},
		//papers: [
		//	{
		//		"title": "Symbol‐Level Precoding for Multi‐Cell ISAC",
		//		"authors": "Nithin Babu (UCL, United Kingdom (Great Britain)); Christos Masouros (University College London, United Kingdom (Great Britain))",
		//	},
		//	{
		//		"title": "Automotive Radar Interference Mitigation Using Phase‐Coded FMCW Waveform",
		//		"authors": "Utku Kumbul (IMEC, The Netherlands); Nikita Petrov (Delft University of Technology, The Netherlands); Cicero S Vaucher (NXP Semiconductors, Eindhoven & Delft University of Technology, The Netherlands); Alexander Yarovoy (TU Delft, The Netherlands)",
		//	},
		//	{
		//		"title": "Design of Communication Signal Processing for LFM‐CPM Joint Communications and Sensing with Circulating Coding",
		//		"authors": "Bohan Liu, Jie Li, Wei Wang and Jiameng Bi (Nanjing University of Aeronautics and Astronautics, China)",
		//	},
		//	{
		//		"title": "An Optimized CSS‐ISAC Signal Design for Localisation with LEO Satellites",
		//		"authors": "Daniel Egea-Roca and José A. López-Salcedo (Universitat Autònoma de Barcelona, Spain); Gonzalo Seco-Granados (Universitat Autonoma de Barcelona, Spain)",
		//	},
		//	{
		//		"title": "A Novel Adaptive Spreading Waveform for Integrated Sensing and Communication",
		//		"authors": "Daljeet Singh (University of Oulu, Finland & Lovely Professional University, India); Atul Kumar (Indian Institute of Technology (BHU), Varanasi, India); Hem Joshi (Thapar University, India); Ashutosh Singh (Thapar Institute of Engineering and Technology, India); Mariella Särestöniemi (University of Oulu & Research Unit of Health Sciences and Technology and Center for Wireless Communication, Finland); Teemu Samuli Myllylä (University of Oulu, Finland); Maurizio Magarini (Politecnico di Milano, Italy))",
		//	},
		//],
		//vimeo: "928691644",
			},
	//{
	//	title: "15min Break",
	//	start: "14:55",
	//	end: "15:10",
	//	break: true,
	//},
	
			{
				title: "Conference recap and closing",
			//	speaker: "André Bourdoux (IMEC), Prof. Gerhard Fettweis (TU Dresden), Prof. Thomas Ußmüller (Universität Innsbruck)",
				start: "15:00",
				end: "15:45",
			//	slides: [ 
			//	{
			//		name: "Conference Summary", 
			//		file: "summary_bourdoux.pdf"
			//	}, 
			//	{
			//		name: "Closing Slides", 
			//		file: "closing_slides.pdf"
			//	},
			//	],
		//vimeo: "928691919",
			}

		]
	}
]
